<template>
  <ModuleHome title="NAVISION.TITLE" fullWidth />
</template>

<script>
import ModuleHome from "@components/Layout/ModuleHome.vue";

export default {
  name: "Navision",

  components: {
    ModuleHome,
  },
};
</script>
